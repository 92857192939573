import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IonicModule } from '@ionic/angular';
import { BuyElectricityControllerService } from '../../../../../../sdk/util-sdk';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  imports: [
    IonicModule
  ],
  standalone: true
})
export class OrderDetailsComponent  implements OnInit {

  @Input() orderDetails: any;

  constructor(private modalRef: BsModalRef,
              private electricityController: BuyElectricityControllerService) { }

  ngOnInit(): void {}

  close(): void {
    this.modalRef.hide();
  }

}
