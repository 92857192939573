<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Select Provider</h5>
    <a (click)="close()">Close</a>
  </div>
  <div class="modal-body">
    <ul class="listview image-listview inset">
      @for (disco of discos; track disco) {
        <li>
          <div class="item" (click)="selectDisco(disco)">
            <img [src]="icon(disco)" alt="image" class="image">
            <div class="in">
              <div>{{ name(disco) }}</div>
              <input class="form-check-input" type="radio"
                     [checked]="disco == selectedDisco">
            </div>
          </div>
        </li>
      }
    </ul>
  </div>
</div>
