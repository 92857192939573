import {Injectable, OnDestroy} from "@angular/core";
import {SubscriptionSink} from "./subscription-sink";
import {Subject} from "rxjs";

@Injectable()
export class UnsubscribeOnDestroyAdapter implements OnDestroy {

  subscriptions = new SubscriptionSink();
  destroy$ = new Subject<void>;

    ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
      this.destroy$.next();
      this.destroy$.complete();
    }
}
