import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { telePhoneNumberValidator } from '../../../shared/utils/utils';
import { PasswordComponent } from '../../../shared/components/password/password/password.component';
import { UserControllerService } from '../../../../../sdk/util-sdk';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    PasswordComponent
  ]
})
export class SignupComponent  implements OnInit {

  constructor(private fb: FormBuilder,
              private userService: UserControllerService) { }

  ngOnInit() {
    this.initForm();
  }

  form: FormGroup;

  initForm(): void {
    this.form = this.fb.group({
      fullName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.email,
        Validators.minLength(2)
      ])],
      phoneNumber: ['', [telePhoneNumberValidator()]],
      password: ['', Validators.required]
    });
  }

  password($event): void {
    this.form.patchValue({ password: $event });
  }

  submit(): void {
    if (this.form.invalid) {
      console.log('invalid form');
      return;
    }

    let signupDto = this.form.value;

    this.userService.registerUser1({ signupDto }).subscribe(res => {
      console.log('success');
    });
  }

  protected readonly SearchCountryField = SearchCountryField;
  protected readonly PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode: boolean = false;
  selectedCountryISO: any = CountryISO['Nigeria'];
}
