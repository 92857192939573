<div class="section mt-3">
  <div class="section-title">Pilled</div>
      <div class="section-title">Service Provider</div>
      <ul class="listview image-listview">
        <li>
          <a class="item" (click)="selectDisco()">
            <img [src]="discoIcon()" alt="image" class="image">
            <div class="in">
              <div>{{ discoName() }}</div>
            </div>
          </a>
        </li>
      </ul>
      <ul class="nav nav-tabs capsuled mt-3" role="tablist">
        @for (tab of tabData; track tab) {
          <li class="nav-item">
            <a class="nav-link" [class.active]="tab == activeTab" (click)="setActiveTab(tab)">
              {{ tab }}
            </a>
          </li>
        }
      </ul>
      <div class="mt-2">
        <div class="title-row">
          <div class="section-title">Meter Number</div>
          <span class="beneficiaries"> Beneficiaries
            <ion-icon class="beneficiaries ion-icon" name="chevron-forward-outline" />
          </span>
        </div>
        <form [formGroup]="form">
          <div class="form-group basic">
            <div class="input-wrapper">
              <input [formControlName]="'meterNumber'" type="text" class="form-control" id="userid1"
                     [placeholder]="'Enter Meter Number'">
              <i class="clear-input">
                <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
              </i>
            </div>
            <error-message
              [controlName]="'meterNumber'"
              [singularError]="true"
              [form]="form"
            />
          </div>
        </form>

        @if (selectedMeter) {
          <div class="card mt-1">
            <div class="card-body">
              {{ selectedMeter.name }}
            </div>
          </div>
        }

        <div class="section-title mt-2">Select Amount</div>

        <div class="row">
          <div class="col">
            <div class="exampleBox rounded" [class.selected]="amountSelected(1000)"
                 (click)="selectAmount(1000)">
              N1,000
            </div>
          </div>
          <div class="col">
            <div class="exampleBox rounded" [class.selected]="amountSelected(2000)"
                 (click)="selectAmount(2000)">
              N2,000
            </div>          </div>
          <div class="col">
            <div class="exampleBox rounded" [class.selected]="amountSelected(3000)"
                 (click)="selectAmount(3000)">
              N3,000
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="exampleBox rounded" [class.selected]="amountSelected(5000)"
                 (click)="selectAmount(5000)">
              N5,000
            </div>
          </div>
          <div class="col">
            <div class="exampleBox rounded" [class.selected]="amountSelected(10000)"
                 (click)="selectAmount(10000)">
              N10,000
            </div>
          </div>
          <div class="col">
            <div class="exampleBox rounded" [class.selected]="amountSelected(20000)"
                 (click)="selectAmount(20000)">
              N20,000
            </div>
          </div>
        </div>
        <form [formGroup]="form" class="mt-3">
          <div class="form-group basic">
            <div class="input-wrapper">
              <input [formControlName]="'amount'" type="text" class="form-control" [placeholder]="'Enter Amount'">
              <i class="clear-input">
                <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
              </i>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-3">
          <button (click)="makePayment()" type="button" class="btn btn-outline-primary me-1 mb-1">Pay</button>
        </div>
      </div>
    </div>
