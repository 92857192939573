<form [formGroup]="form">
  <div class="row">
    <div class="col-12">
      <div class="mb-3">
        <div class="form-group basic">
          <div class="input-wrapper">
            <label class="label">Password</label>
            <input class="form-control" [formControlName]="'password'"  placeholder="{{ 'Enter password' }}" type="password" />
            <password-custom-error [form]="form" *ngIf="form.get('password').value && form.get('password').touched && form.get('password').invalid"></password-custom-error>
        </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="mb-3">
        <div class="form-group basic">
          <div class="input-wrapper">
          <label class="label">Confirm Password</label>
            <input class="form-control" [formControlName]="'confirmPassword'" placeholder="{{'Confirm password'}}" type="password" />
        </div>
        </div>
        </div>
    </div>
  </div>

</form>
