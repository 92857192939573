import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, from, Observable, Observer } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { mergeMap } from 'rxjs/operators';
import { BrowserInfo, BrowserInformationService } from './browser-information.service';
import { PageManager } from './page-manager';
import { AuthenticationService } from './authentication.service';
import {environment} from '../environments/environment';
import moment from "moment";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private static TOKEN_NAME = 'TOKEN';
  private static headers: any = {};
  private _lastSeen!: moment.Moment;
  private browserInfo: BrowserInfo | undefined;
  private macAddress: string | undefined;
  private buildVersion: string | undefined;

  constructor(
    private toastr: ToastrService,
    private keycloakService: KeycloakService,
    private browserInfoService: BrowserInformationService,
    private pageManager: PageManager,
    private authenticationService: AuthenticationService
  ) {
    const lastSeen = localStorage.getItem(HttpInterceptorService.name + '.lastSeen');
    if (lastSeen) {
      // eslint-disable-next-line import/namespace
      this._lastSeen = moment.unix(parseInt(lastSeen, 10));
    }
    this.browserInfo = this.browserInfoService.getBrowserInfo();
  }

  private _httpError: EventEmitter<HttpErrorResponse> = new EventEmitter();

  public get httpError(): any {
    return this._httpError;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('LOCAL')) {
      return next.handle(req.clone());
    }
    const httpHeaders = {} as any;
    const handled: Observable<HttpEvent<any>> = from(
      this.keycloakService.getToken().catch((reason) => console.log(reason))
    ).pipe(
      mergeMap((token) => {
        if (token) {
          httpHeaders['Authorization'] = 'bearer ' + token;
        }

        if (this.pageManager.currentUserAccount$.value.accountCode) {
          httpHeaders['X-ACCOUNT-CODE'] = this.pageManager.currentUserAccount$.value.accountCode;
        }

        if (this.macAddress) {
          httpHeaders['X-Device-ID'] = this.macAddress;
        }

        if (this.buildVersion) {
          httpHeaders['X-Plugin-Version'] = this.buildVersion;
        }

        if (this.browserInfo && this.browserInfo.browserVersion) {
          httpHeaders['X-BROWSER-VERSION'] = this.browserInfo.browserVersion;
        }
        if (
          token &&
          moment().subtract(environment.sessionTimeout, 'minutes').isAfter(this._lastSeen)
        ) {
          this.toastr.info(
            `Session timed out after ${environment.sessionTimeout} mins of inactivity`,
            'Session Timeout',
            {
              disableTimeOut: false
            }
          );
          // TODO: Update logout link to '/login'
          this.authenticationService.logout(window.location.origin);
        }
        this._lastSeen = moment();
        localStorage.setItem(
          HttpInterceptorService.name + '.lastSeen',
          `${this._lastSeen.valueOf() / 1000}`
        );
        return next.handle(req.clone({ setHeaders: httpHeaders }));
      })
    );

    const subject: AsyncSubject<HttpEvent<any>> = new AsyncSubject();
    handled.subscribe(subject);

    subject.subscribe(
      // eslint-disable-next-line rxjs/no-async-subscribe
      async (event: HttpEvent<any>) => {
        if (event instanceof HttpErrorResponse) {
          if (event.status === 401) {
            this.toastr.info(
              `Session timed out after ${environment.sessionTimeout} mins of inactivity`,
              'Session Timeout',
              {
                disableTimeOut: false
              }
            );
            await this.authenticationService.login({
              redirectUri: window.location.origin
            });
            return;
          }
          this._httpError.emit(event);
        }
      },
      // eslint-disable-next-line rxjs/no-async-subscribe
      async (err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status < 1) {
            // this.toastr.error('Please check your internet connection', 'Failed to contact server');
            // this.showOfflineDialog();
          } else if (err.status === 401) {
            await this.authenticationService.logout();
            await this.authenticationService.login({
              redirectUri: window.location.origin + '/dashboard'
            });
            return;
          } else if (err.status === 404) {
            return;
          } else if (err.status === 403) {
            // this.authenticationService.forbidAccess();
            return;
          }

          this._httpError.emit(err);
        }
      }
    );
    // eslint-disable-next-line rxjs/no-create
    return Observable.create((obs: Observer<HttpEvent<any>>) => {
      subject.subscribe(obs);
    });
  }
}
