import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../shared/utils/utils';
import { IonicModule } from '@ionic/angular';
import { DiscoSelectorComponent } from './disco-selector/disco-selector.component';
import { SharedModule } from '../../../shared/shared.module';
import {
  FlutterwaveService,
  InlinePaymentOptions,
  MakePaymentComponent,
  PaymentSuccessResponse
} from 'flutterwave-angular-v3';
import { ToastrModule } from 'ngx-toastr';
import { DiscoPojo, MeterPojo } from '../../../../../sdk/util-sdk';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MeterValidator } from '../../../shared/validators/meter.validator';
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import { EmComponent } from '../../../shared/components/em/em.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderDetailsComponent } from './order-details/order-details.component';
import DiscoEnum = DiscoPojo.DiscoEnum;

@Component({
  selector: 'app-purchase-electricity-component',
  templateUrl: './purchase-electricity.component.html',
  styleUrls: ['./purchase-electricity.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    SharedModule,
    DiscoSelectorComponent,
    MakePaymentComponent,
    ToastrModule,
    ReactiveFormsModule,
    EmComponent,
    OrderDetailsComponent
  ]
})
export class PurchaseElectricityComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  tabData: tab[] = Utils.enumValues(tab);

  setActiveTab(tab: tab): void {
    this.activeTab = tab;
  }

  activeTab: tab = tab.Prepaid;

  form: FormGroup
  selectedMeter: MeterPojo | null = null; // Store the validation response

  constructor(private fb: FormBuilder,
              private bsModalService: BsModalService,
              private meterValidator: MeterValidator,
              private flutterwaveService: FlutterwaveService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      disco: [DiscoEnum.AEDC],
      meterNumber: [''],
      amount: [''],
      tariff: ['']
    });

    this.form.controls.disco.addValidators([this.meterValidator.meterExistsValidator(this.form.controls.disco.value)])

    this.subscriptions.sink = this.meterValidator.getLatestValidationResponse().subscribe((response) => {
      this.selectedMeter = response;
    });
  }

  customerDetails = {
    name: "Isa Umar",
    email: "customer@mail.com",
    phone_number: "08100000000",
  };

  customizations = {
    title: "Serverna Util",
    description: "Payment for AEDC",
    logo: "https://serverna.dev/assets/img/serverna.png",
  };

  meta = { consumer_id: "12345", consumer_mac: "92a3-912ba-1192a" };


  paymentData: InlinePaymentOptions = {
    public_key: 'FLWPUBK_TEST-7cde8f939b0176409da4eba1b814ed22-X',
    tx_ref: this.generateReference(),
    amount: 100,
    currency: "NGN",
    payment_options: "card,ussd",
    redirect_url: "",
    meta: this.meta,
    customer: this.customerDetails,
    customizations: this.customizations,
    callback: this.makePaymentCallback,
    onclose: this.closedPaymentModal,
    callbackContext: this,
  };

  makePaymentCallback(response: PaymentSuccessResponse): void {
    this.flutterwaveService.closePaymentModal(5);
  }
  closedPaymentModal(): void {
  }

  generateReference(): string {
    let date = new Date();
    return date.getTime().toString();
  }

  makePayment() {
    // this.flutterwaveService.inlinePay(this.paymentData);
    this.showDetails();
  }

  amountSelected(number: number) {
    return this.form.controls.amount.value == number;
  }

  selectAmount(amount: number) {
    this.form.patchValue({ amount });
  }

  showDetails(): void {
    this.bsModalService.show(OrderDetailsComponent, {
      initialState: {
        orderDetails: this.form.value
      },
      class: 'modal-bottom-drawer',
    });
  }

  selectDisco(): void {
    const ref = this.bsModalService.show(DiscoSelectorComponent, {
      initialState: {
        selectedDisco: this.form.controls.disco.value
      },
      class: 'modal-bottom-drawer',
    });

    ref.content.selectedDiscoChange.subscribe((disco) => {
      this.form.patchValue({ disco });
    });

  }

  discoIcon() {
    return Utils.discoIconPath(this.form.controls.disco.value);
  }

  discoName() {
    return Utils.discoName(this.form.controls.disco.value);
  }
}

enum tab {
  Prepaid = 'Prepaid',
  Postpaid = 'Postpaid',
}
