import { commonEnv } from './environment.common';

const env: Partial<typeof commonEnv> = {
  staging: true,
  keycloakConfig: {
    ...commonEnv.keycloakConfig,
    url: 'https://sandbox.util.ng/auth'
  }
};

export const environment = {
  ...commonEnv,
  ...env
};
