<div id="appCapsule">
  <div class="section mt-2 text-center">
    <h1>Register now</h1>
    <h4>Create an account</h4>
  </div>
  <div class="section mb-5 p-2">
    <form action="index.html">
      <div class="card">
        <div class="card-body">

          <form [formGroup]="form">

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="email1">Name</label>
              <input type="text" class="form-control" id="name" placeholder="Your name">
              <i class="clear-input">
                <ion-icon name="close-circle"/>
              </i>
            </div>
          </div>

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label">Phone Number</label>
              <ngx-intl-tel-input
                class="form-control w-100 d-flex"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="selectedCountryISO"
                [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.National"
                [maxLength]="15"
                [phoneValidation]="true"
              ></ngx-intl-tel-input>
            </div>
          </div>

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="email1">Email</label>
              <input type="email" class="form-control" id="email1" placeholder="Your e-mail">
              <i class="clear-input">
                <ion-icon name="close-circle"/>
              </i>
            </div>
          </div>

          <!--<div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="password1">Password</label>
              <input type="password" class="form-control" id="password1" autocomplete="off"
                     placeholder="Your password">
              <i class="clear-input">
                <ion-icon name="close-circle"/>
              </i>
            </div>
          </div>

          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="password2">Password Again</label>
              <input type="password" class="form-control" id="password2" autocomplete="off"
                     placeholder="Type password again">
              <i class="clear-input">
                <ion-icon name="close-circle"/>
              </i>
            </div>
          </div>-->

            <password
              (password)="password($event)"
            />

          <div class="custom-control custom-checkbox mt-2 mb-1">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="customCheckb1">
              <label class="form-check-label" for="customCheckb1">
                I agree <a href="#" data-bs-toggle="modal" data-bs-target="#termsModal">terms and
                conditions</a>
              </label>
            </div>
          </div>
          </form>
        </div>
      </div>



      <div class="form-button-group transparent">
        <button (click)="submit()" class="btn btn-primary btn-block btn-lg">Sign Up</button>
      </div>

    </form>
  </div>
</div>
