import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import {fromEvent, merge, of, Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {AuthenticationService} from "../../../services/authentication.service";
import {PageManager} from "../../../services/page-manager";
import {UserAccount} from "../../../models/account/user-account.model";
import {IonicModule} from "@ionic/angular";
import {BsModalService} from "ngx-bootstrap/modal";
import {SideNavComponent} from "../side-nav/side-nav.component";
import {ToastrService} from "ngx-toastr";
import {SharedModule} from "../../../shared/shared.module";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  imports: [
    IonicModule,
    SharedModule,
    SideNavComponent,
    RouterLink
  ],
  standalone: true
})
export class HeaderComponent implements OnInit, OnDestroy {

  routeData: any;
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  currentAccount: UserAccount;
  stop$ = new Subject();

  constructor(private authService: AuthenticationService,
              private pageManager: PageManager,
              private modalService: BsModalService,
              private toastr: ToastrService,
              private router: Router, route: ActivatedRoute) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.snapshot?.data;
      }
    })
  }


  ngOnInit(): void {
    this.hasNetworkConnection();

    this.pageManager.currentUserAccount$
      .subscribe(userAccount => {
        this.currentAccount = userAccount;
      });

    this.authService.getUser().pipe(takeUntil(this.stop$))
      .subscribe((user) => {
        if (this.currentAccount) {
          this.pageManager.currentUserAccount$.next(this.currentAccount);
        } else if (user) {
          if (user.accounts) {
            this.currentAccount = user.accounts[0];
            this.pageManager.setCurrentUserAccount(user.accounts[0]);
          }

        }

      });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
    this.stop$.complete();
  }

  hasNetworkConnection() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(map(() => navigator.onLine)).subscribe(status => {
      this.networkStatus = status;
    });
  }


  login(): void {
    this.authService.login({
      redirectUri: `${window.location.origin}/dashboard`
    });
  }

  openSidebar(): void {
    this.modalService.show(SideNavComponent, {
      class: 'modal panelbox panelbox-left'
    });
  }
}
