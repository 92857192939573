import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserInformationService {
  private static _browserInfo: BrowserInfo;

  constructor() {}

  getBrowserInfo(): BrowserInfo{
    let info: BrowserInfo = {
      browserName: this.detectBrowserName(),
      browserVersion: this.detectBrowserVersion()
    };

    return info;
  }

  detectBrowserName() { 
    const agent = window?.navigator?.userAgent.toLowerCase();
    if(agent == null || agent == undefined) return ''
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
   
  detectBrowserVersion(){
      var userAgent = navigator.userAgent, tem
      if(userAgent == null || userAgent == undefined) return '';

      let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE '+(tem[1] || '');
      }
      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join('-');
  }

  public getInfo(): BrowserInfo {
    if(BrowserInformationService._browserInfo == undefined){
      this.setInfo();
      return BrowserInformationService._browserInfo;
    }
    return BrowserInformationService._browserInfo;
  }

  private setInfo(){
    BrowserInformationService._browserInfo = this.getBrowserInfo();
  }
}

export interface BrowserInfo {
  browserName: string,
  browserVersion: string;
}
