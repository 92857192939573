/**
 * Util API
 * The Util RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentVerificationPojo { 
    paymentStatus?: PaymentVerificationPojo.PaymentStatusEnum;
    organizationName?: string;
    servicePaidFor?: string;
    expiryDate?: string;
    planName?: string;
}
export namespace PaymentVerificationPojo {
    export type PaymentStatusEnum = 'ACTIVE' | 'REVERSED' | 'PENDING_CONFIRMATION' | 'NOT_YET_RECEIVED' | 'CANCELLED' | 'REMOVED';
    export const PaymentStatusEnum = {
        ACTIVE: 'ACTIVE' as PaymentStatusEnum,
        REVERSED: 'REVERSED' as PaymentStatusEnum,
        PENDING_CONFIRMATION: 'PENDING_CONFIRMATION' as PaymentStatusEnum,
        NOT_YET_RECEIVED: 'NOT_YET_RECEIVED' as PaymentStatusEnum,
        CANCELLED: 'CANCELLED' as PaymentStatusEnum,
        REMOVED: 'REMOVED' as PaymentStatusEnum
    };
}


