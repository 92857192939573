export enum PortalAccountType {
  // ICS = 'ICS',
  // REVENUE_AUTHORITY = 'REVENUE_AUTHORITY',
  ENROLLMENT_SERVICE_AGENT = 'ENROLLMENT_SERVICE_AGENT',
  AUTHENTICATION_SERVICE_AGENT = 'AUTHENTICATION_SERVICE_AGENT',
  NIMC = 'NIMC',
  CBS_PORTAL = 'CBS_PORTAL',
  OTHER_CUSTOMER = 'OTHER_CUSTOMER',
  VENDOR = 'VENDOR',
  VENDOR_ENROLMENT_SOFTWARE = 'VENDOR_ENROLMENT_SOFTWARE'
}

export enum PortalAccountRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  ASA_ADMIN = 'ASA_ADMIN',
  ESA_ADMIN = 'ESA_ADMIN',
  ASA_APPROVER = 'ASA_APPROVER',
  FINANCE_OFFICER = 'FINANCE_OFFICER',
  MANAGEMENT = 'MANAGEMENT',
  SUPPORT = 'SUPPORT'
}
