import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ForbiddenService {

  private submitting: Function = undefined;

  constructor() { }

  setFunction(value: Function) {
    this.submitting = value;
  }

  getFunction(): Function {
    return this.submitting;
  }
}
