import {PermissionType} from '../account/permission-type.enum';
import {UserAccount} from '../account/user-account.model';
import {Account} from '../account/account.model';
import {RolePojo} from '../account/role.pojo.model';
import {PortalAccountRole} from '../account/portal-account-type.enum';

export class User {

  public accounts!: UserAccount[];
  public email!: string;
  public firstname!: string;
  public surname!: string;
  public phone!: string;
  public mobileNumber!: string;
  public requiresPasswordUpdate!: boolean;
  public userId!: string;
  public status!: string;
  public id!: number;
  public username!: string;
  public accountPojo!: Account;
  public fullAddress!: string;
  public nin!: string;
  public loginId!: string;


  constructor(data: any) {
    Object.assign(this, data);
    if (data && data.accounts) {
      this.accounts = data.accounts;
    }
    if (data && data.accountPojo) {
      this.accountPojo = new Account(data.accountPojo);
    }
  }


  public permissions(): string[] {
    if (!this.accountPojo) {
      return [];
    }
    const roles: RolePojo[] = this.accountPojo.roles;
    let permits: PermissionType[] = [];
    roles.forEach(r => {
      let p: PermissionType[];
      p = r.permissions;
      permits = permits.concat(...p);
    });
    return permits.filter((v, i) => permits.indexOf(v) === i);
  }

  public hasPermission(permissionName: string | PermissionType) {
    const accounts: UserAccount[] = this.accounts.filter((acct: any) => {
      return acct.permissions.filter((it: string) => it === permissionName).length;
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }

  public getAccountsWithPermission(permission: string | PermissionType): UserAccount[] {
    return this.accounts.filter((it: UserAccount) => it.hasPermission(permission));
  }


  public getRole(roles: RolePojo[]): RolePojo | undefined {
    return roles.find(r => {
      let role: RolePojo | null = null;

      switch (r.name) {
        case PortalAccountRole.ADMIN:
          role = r;
          break;

        case PortalAccountRole.MANAGEMENT:
          role = r;
          break;

        case PortalAccountRole.FINANCE_OFFICER:
          role = r;
          break;

        case PortalAccountRole.ASA_ADMIN:
          role = r;
          break;

        case PortalAccountRole.ASA_APPROVER:
          role = r;
          break;

        case PortalAccountRole.SUPPORT:
          role = r;
          break;
      }

      return role;
    });
  }


  public hasRole(roleName: string): boolean {
    const accounts: UserAccount[] = this.accounts.filter((acct: any) => {
      return acct.roles.filter((it: string) => it === roleName).length;
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }

  public isPortalAccountType(type: string): boolean {
    const accounts: UserAccount[] = this.accounts.filter((acct: any) => {
      return acct.accountType.toUpperCase() === type.toUpperCase();
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }
}
