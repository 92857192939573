import { Routes } from '@angular/router';
import {
  PurchaseElectricityComponent
} from './components/electricity/purchase-electricity/purchase-electricity.component';
import { HomeComponent } from './components/home/home.component';
import { SignupComponent } from './components/signup/signup/signup.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'purchase-electricity', component: PurchaseElectricityComponent },
];
