<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-body p-0">
      <!-- profile box -->
      <div class="profileBox pt-2 pb-2">
        <div class="image-wrapper">
          <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged  w36">
        </div>
        <div class="in">
          <strong>Sebastian Doe</strong>
          <div class="text-muted">4029209</div>
        </div>
        <a href="#" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
          <ion-icon name="close-outline"></ion-icon>
        </a>
      </div>
      <!-- * profile box -->


      <!-- menu -->
      <div class="listview-title mt-1">Menu</div>
      <ul class="listview flush transparent no-line image-listview">
        <li>
          <a href="index.html" class="item">
            <div class="icon-box bg-primary">
              <ion-icon name="pie-chart-outline"></ion-icon>
            </div>
            <div class="in">
              Overview
              <span class="badge badge-primary">10</span>
            </div>
          </a>
        </li>
        <li>
          <a href="app-pages.html" class="item">
            <div class="icon-box bg-primary">
              <ion-icon name="document-text-outline"></ion-icon>
            </div>
            <div class="in">
              Pages
            </div>
          </a>
        </li>
        <li>
          <a href="app-components.html" class="item">
            <div class="icon-box bg-primary">
              <ion-icon name="apps-outline"></ion-icon>
            </div>
            <div class="in">
              Components
            </div>
          </a>
        </li>
        <li>
          <a href="app-cards.html" class="item">
            <div class="icon-box bg-primary">
              <ion-icon name="card-outline"></ion-icon>
            </div>
            <div class="in">
              My Cards
            </div>
          </a>
        </li>
      </ul>
      <!-- * menu -->

      <div class="dark-mode-settings">
        <a (click)="toggleDarkMode()" class="dark-mode-icon">
          <ion-icon name="moon-outline"></ion-icon>
        </a>
      </div>
    </div>
  </div>
</div>
