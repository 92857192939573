@if (isTouched && errors && errors.length) {
  @if (singularError) {
    <small
      [style.font-size.px]="fontSize"
      [style.color]="color + '!important'"
      class="text-danger"
    >{{ firstError }}<br
    /></small>
  } @else {
    @for (e of errors; track e) {
      <small class="text-danger">{{ e }}<br /></small>
    }
  }
}
