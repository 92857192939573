/**
 * Util API
 * The Util RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkflowDefinition } from './workflow-definition';
import { PortalUser } from './portal-user';


export interface Workflow { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: Workflow.StatusEnum;
    deactivatedBy?: PortalUser;
    reviewStatus?: string;
    reasonForReview?: string;
    reviewedAt?: string;
    reviewer?: PortalUser;
    lastCompletedStep?: number;
    workflowDefinition?: WorkflowDefinition;
}
export namespace Workflow {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


