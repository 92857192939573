<ul>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('password').hasError('required') || handleErrorType('noLowerCase') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noLowerCase') ? 'fa-times' : 'fa-check'" class="fa"></i>
      {{'No lowercase'}}
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('password').hasError('required') || handleErrorType('noUpperCase') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noUpperCase') ? 'fa-times' : 'fa-check'" class="fa"></i>
      {{'Missing uppercase'}}
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('password').hasError('required') || handleErrorType('noNumber') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noNumber') ? 'fa-times' : 'fa-check'" class="fa"></i>
      {{'Missing number'}}
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('password').hasError('required') || handleErrorType('noSymbol') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('noSymbol') ? 'fa-times' : 'fa-check'" class="fa"></i>
      {{'Missing special character'}}
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('password').hasError('required') || handleErrorType('invalidLengthMin') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('invalidLengthMin') ? 'fa-times' : 'fa-check'" class="fa"></i>
      {{'Password must be at least 5 characters long'}}
    </small>
  </li>
  <li>
    <small class="custom-text-size" [ngClass]="form.get('password').hasError('required') || handleErrorType('invalidLengthMax') ? 'text-danger' : 'text-success'">
      <i [ngClass]="handleErrorType('invalidLengthMax') ? 'fa-times' : 'fa-check'" class="fa"></i>
      {{'Password must be at least 5 characters long'}}
    </small>
  </li>
</ul>
