/**
 * Util API
 * The Util RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentTransaction } from './payment-transaction';
import { Invoice } from './invoice';


export interface PaymentProcessingPojo { 
    transaction?: PaymentTransaction;
    invoice?: Invoice;
    paymentType?: PaymentProcessingPojo.PaymentTypeEnum;
}
export namespace PaymentProcessingPojo {
    export type PaymentTypeEnum = 'USER_INVOICE';
    export const PaymentTypeEnum = {
        USER_INVOICE: 'USER_INVOICE' as PaymentTypeEnum
    };
}


