/**
 * Util API
 * The Util RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { Country } from './country';
import { PortalUser } from './portal-user';


export interface FwChargeCard { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: FwChargeCard.StatusEnum;
    deactivatedBy?: PortalUser;
    first6Digits?: string;
    last4Digits?: string;
    issuer?: string;
    type?: string;
    chargeToken?: string;
    email?: string;
    expiresOn?: string;
    portalAccount?: PortalAccount;
    country?: Country;
}
export namespace FwChargeCard {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


