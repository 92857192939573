import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MeterValidationControllerService, MeterValidationDto, MeterPojo } from '../../../../sdk/util-sdk';
import DiscoEnum = MeterValidationDto.DiscoEnum;

@Injectable({
  providedIn: 'root'
})
export class MeterValidator {
  private latestResponseSubject: BehaviorSubject<MeterPojo | null> = new BehaviorSubject<MeterPojo | null>(null);

  constructor(private meterValidationController: MeterValidationControllerService) {}

  meterExistsValidator(disco: DiscoEnum): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      return this.meterValidationController
        .validateMeterNumber({ meterValidationDto: { meterNumber: control.value, disco: disco } })
        .pipe(
          delay(500),
          tap((v: MeterPojo) => {
            this.latestResponseSubject.next(v);
          }),
          map((v: MeterPojo) => {
            return v && v.name
              ? null
              : {
                invalidMeter: true,
              };
          }),

          catchError(() => {
            return of({ downTime: true });
          })
        );
    };
  }

  getLatestValidationResponse(): Observable<MeterPojo | null> {
    return this.latestResponseSubject.asObservable();
  }
}
