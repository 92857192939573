<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Purchase Details</h5>
    <a (click)="close()">Close</a>
  </div>
  <div class="modal-body">
    @if (orderDetails) {
     <div class="section mt-2 mb-2">
      <div class="listed-detail mt-3">
        <div class="icon-wrapper">
          <div class="iconbox">
            <ion-icon name="arrow-forward-outline" role="img" class="md hydrated"
                      aria-label="arrow forward outline"></ion-icon>
          </div>
        </div>
        <h3 class="text-center mt-2">Payment Sent</h3>
      </div>

      <ul class="listview flush transparent simple-listview no-space mt-3">
        <li>
          <strong>Status</strong>
          <span class="text-success">Success</span>
        </li>
        <li>
          <strong>To</strong>
          <span>Jordi Santiago</span>
        </li>
        <li>
          <strong>Bank Name</strong>
          <span>Envato Bank</span>
        </li>
        <li>
          <strong>Transaction Category</strong>
          <span>Shopping</span>
        </li>
        <li>
          <strong>Receipt</strong>
          <span>Yes</span>
        </li>
        <li>
          <strong>Date</strong>
          <span>Sep 25, 2020 10:45 AM</span>
        </li>
        <li>
          <strong>Amount</strong>
          <h3 class="m-0">$ 24</h3>
        </li>
      </ul>
    </div>
    }
  </div>
</div>
