export interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
}

export namespace AuthResponse {
  export type KeycloakCookies =
    'KEYCLOAK_SESSION_LEGACY'
    | 'KEYCLOAK_IDENTITY_LEGACY'
    | 'KEYCLOAK_IDENTITY'
    | 'AUTH_SESSION_ID_LEGACY'
    | 'AUTH_SESSION_ID'
    | 'KEYCLOAK_SESSION'
    | 'JSESSIONID'
    | 'KEYCLOAK_LOCALE';
  export const KeycloakCookiesEnum = {
    KEYCLOAK_SESSION_LEGACY: 'KEYCLOAK_SESSION_LEGACY' as KeycloakCookies,
    KEYCLOAK_IDENTITY_LEGACY: 'KEYCLOAK_IDENTITY_LEGACY' as KeycloakCookies,
    KEYCLOAK_IDENTITY: 'KEYCLOAK_IDENTITY' as KeycloakCookies,
    AUTH_SESSION_ID_LEGACY: 'AUTH_SESSION_ID_LEGACY' as KeycloakCookies,
    AUTH_SESSION_ID: 'AUTH_SESSION_ID' as KeycloakCookies,
    JSESSIONID: 'JSESSIONID' as KeycloakCookies,
    KEYCLOAK_LOCALE: 'KEYCLOAK_LOCALE' as KeycloakCookies,
  };
}
