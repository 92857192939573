import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidator {

  static mustBeValidLength(length: number, error: ValidationErrors): ValidatorFn {
    // @ts-ignore
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (control.value.length < length && length === 6) { return error; }

      if (control.value.length > length && length === 15) { return error; }

    };


  }

  static mustContain(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;

    };
  }
}
