/**
 * Util API
 * The Util RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { BinaryData } from './binary-data';


export interface PortalUser { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalUser.StatusEnum;
    deactivatedBy?: PortalUser;
    firstName?: string;
    lastName?: string;
    otherNames?: string;
    email?: string;
    phoneNumber?: string;
    userId?: string;
    generatedPassword?: string;
    username?: string;
    dateOfBirth?: string;
    displayName?: string;
    gender?: PortalUser.GenderEnum;
    setupComplete?: boolean;
    emailVerified?: boolean;
    preferredName?: string;
    userVerified?: boolean;
    image?: BinaryData;
    address?: Address;
}
export namespace PortalUser {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        UNSPECIFIED: 'UNSPECIFIED' as GenderEnum,
        OTHER: 'OTHER' as GenderEnum
    };
}


