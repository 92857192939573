// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const commonEnv = {
  production: false,
  staging: false,
  local: false,
  keycloakConfig: {
    clientId: 'util-frontend',
    realm: 'util',
    url: 'https://util.ng/auth'
  },
  apiBaseUrl: '/api',
  sessionTimeout: 1440,
  utilReportUrl: '/report-api',
  maxFileSizeInMb: 3,
  maxNumberOfDocuments: 3,
  registrationPath: '/newuser/signup',
  refCodeKey: 'ref-code',
  darkMode: {
    default: true, // Set dark mode as main theme
    localMode: { // Activate dark mode between certain times of the day
      enable: true, // Enable or disable local dark mode
      startTime: 20, // Start at 20:00
      endTime: 7, // End at 07:00
    },
    autoDetect: { // Auto detect user's preferences and activate dark mode
      enable: true,
    }
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
