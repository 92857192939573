import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AsyncSubject,
  BehaviorSubject,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription, tap
} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions } from 'keycloak-js';
import { User } from '../models/user/user.model';
import { UserAccount } from '../models/account/user-account.model';
import { PermissionType } from '../models/account/permission-type.enum';
import { Constants } from '../models/enum/constants';
import { UserControllerService } from '../../../sdk/util-sdk';
import { ForbiddenService } from '../components/error-pages/forbidden/forbidden.service';
import { Constant } from '../models/enum/constant';
import { PageManager } from './page-manager';
import {AuthResponse} from "../models/auth/auth-response";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static _user: User;
  private static user: Subject<User | null> = new BehaviorSubject(undefined);
  private static userFetching: Subject<boolean | null> = new BehaviorSubject(false);
  private static loggingOut: Subject<boolean | null> = new BehaviorSubject(false);
  private static token: Subject<string | null> = new ReplaySubject(undefined);
  private static ongoingFetch: Observable<any> | null;
  private static initialized: boolean;
  private static _userFetching = false;
  public static _currentUserAccount: UserAccount | undefined;
  private static currentUserAccount$: Subject<UserAccount | null> = new ReplaySubject(undefined);
  public allAccounts!: UserAccount[];

  sub: Subscription;

  user$: Observable<User | null> = AuthenticationService.user.asObservable();

  constructor(
    private httpClient: HttpClient,
    private keycloak: KeycloakService,
    private router: Router,
    private pageManager: PageManager,
    private userController: UserControllerService,
    private forbiddenService: ForbiddenService
  ) {
    AuthenticationService.user.subscribe((user: any) => {
      if (user === undefined) {
        return;
      }
      AuthenticationService.initialized = true;
      AuthenticationService._user = user;
    });

    AuthenticationService.userFetching.subscribe((v) => (AuthenticationService._userFetching = v));
  }

  public getLastProtectedUrl(): string | null {
    return null;
  }

  public logout(redirectUri?: string): Observable<void> {
    AuthenticationService.loggingOut.next(true);
    this.pageManager.clearAllData();
    this.keycloak.clearToken();
    localStorage.removeItem(Constant.LOGGED_IN);
    return from(this.keycloak.logout(redirectUri)).pipe(
      tap((x) => AuthenticationService.user.next(null))
    );
  }

  public login(loginOptions: KeycloakLoginOptions): Promise<void> {
    this.keycloak.addTokenToHeader()
    return this.keycloak.logout(this.keycloak.getKeycloakInstance().createLoginUrl(loginOptions));
  }

  public fetchUser(): Observable<User> {
    if (AuthenticationService.initialized) {
      return of(AuthenticationService._user);
    } else if (AuthenticationService._userFetching) {
      return AuthenticationService.ongoingFetch;
    }
    return this.fetch();
  }

  public refreshUserState(): void {
    this.userController.userDetails().subscribe((v) => {
      AuthenticationService.user.next(new User(v));
      this.router.navigate(['']);
    });
  }

  private handleUser(userResponse: User, user: User): void {
    AuthenticationService.user.next(user);
    AuthenticationService.userFetching.next(false);
    AuthenticationService.ongoingFetch = null;
  }

  private fetch(): Observable<any> {
    const wrapper = new AsyncSubject();
    AuthenticationService.ongoingFetch = wrapper;
    AuthenticationService.userFetching.next(true);
    this.httpClient.get(`${environment.apiBaseUrl}/me`).subscribe(
      (u: any) => {
        const user = new User(u);
        wrapper.next(user);
        wrapper.complete();

        AuthenticationService.user.next(user);
        AuthenticationService.ongoingFetch = null;
      },
      (err: unknown) => {
        wrapper.error(err);
        AuthenticationService.user.next(undefined);
      }
    );
    return AuthenticationService.ongoingFetch;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public forbidAccess(message?: string, button?: string, func?: Function): void {
    if (func) {
      this.forbiddenService.setFunction(func);
    }
    this.router.navigate(['/forbidden'], { state: { message, button } });
  }
  public getToken(): Subject<string> {
    return AuthenticationService.token;
  }

  public getAccount(): Subject<UserAccount> {
    return AuthenticationService.currentUserAccount$;
  }

  private permissions(): string[] {
    const currentAccount = AuthenticationService._currentUserAccount;
    if (!currentAccount) {
      return [];
    }
    return currentAccount.permissions;
  }

  public setCurrentAccount(account: UserAccount): void {
    this.setCurrentUserAccount(account);
  }

  public hasPermission(permissionName: string | PermissionType): boolean {
    return this.permissions().filter((it: string) => it === permissionName).length > 0;
  }

  public hasAnyPermission(permissions: string[] | PermissionType[]): boolean {
    for (const permission of permissions) {
      if (this.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }

  hasRole(role: string): boolean {
    return AuthenticationService._currentUserAccount?.roles.find((value) => value == role) != null;
  }

  hasAnyRole(roles: string[]): boolean {
    for (const role of roles) {
      if (this.hasRole(role)) {
        return true;
      }
    }
    return false;
  }

  public hasAccountType(accountType: string): boolean {
    return AuthenticationService._currentUserAccount?.accountType === accountType;
  }

  public getUser(): Subject<User | null> {
    return AuthenticationService.user;
  }

  public checkFetchingUser(): Subject<boolean> {
    return AuthenticationService.userFetching;
  }

  public checkLoggingOut(): Subject<boolean> {
    return AuthenticationService.loggingOut;
  }

  private getCurrentUserAccountFromStorage(): any {
    return this.pageManager.getData('USER_ACCOUNT', 'currentAccount', Constants.Storage.LOCAL);
  }
  private setCurrentUserAccount(userAccount: UserAccount): void {
    if (!userAccount) {
      AuthenticationService.currentUserAccount$.next(this.getCurrentUserAccountFromStorage());
    } else {
      AuthenticationService.currentUserAccount$.next(userAccount);
      this.pageManager.storeData(
        'USER_ACCOUNT',
        'currentAccount',
        userAccount,
        Constants.Storage.LOCAL
      );
    }
  }

  private getCurrentUserAccountInStorage(user: User): UserAccount | null | undefined {
    const accountInStorage: UserAccount = this.getCurrentUserAccountFromStorage();
    if (!accountInStorage) {
      return null;
    }
    return this.allAccounts.find((value) => value.accountCode == accountInStorage.accountCode);
  }

  getUserFromLocalStorage(): User {
    return this.pageManager.getData('USER', 'user', Constants.Storage.LOCAL);
  }

  setTokenFromBackend(authResponse: AuthResponse): void {
    const enums = AuthResponse.KeycloakCookiesEnum;
    this.keycloak.getKeycloakInstance().authenticated = true;
    this.keycloak.getKeycloakInstance().token = authResponse.access_token;
    this.keycloak.getKeycloakInstance().refreshToken = authResponse.refresh_token;
    this.keycloak.getKeycloakInstance().sessionId = authResponse.session_state;

  }
}
