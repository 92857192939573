import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient } from "@angular/common/http";
import {HttpInterceptorService} from "./services/http-interceptor.service";
import {AuthenticationService} from "./services/authentication.service";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {environment} from "./environments/environment";
import {filter, mergeMap} from "rxjs/operators";
import { provideIonicAngular } from '@ionic/angular/standalone';
import {provideToastr} from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    importProvidersFrom([BrowserAnimationsModule]),
    provideIonicAngular({}),
    provideToastr()
  ]
};

/**
 *
 * @param keycloak
 * @param authenticationService
 */
function initializeKeycloak(
  keycloak: KeycloakService,
  authenticationService: AuthenticationService
): () => Promise<boolean> {
  keycloak.keycloakEvents$
    .pipe(
      filter((value) => value.type === KeycloakEventType.OnAuthSuccess),
      mergeMap((value) => authenticationService.fetchUser())
    )
    .subscribe();
  const orgOpen = window.open;
  window.open = function (
    url?: string,
    target?: string,
    features?: string,
    replace?: boolean
  ): WindowProxy | null {
    if (url && url.includes(environment.utilReportUrl) && !url.includes('access_token')) {
      keycloak
        .getToken()
        .then((value) => {
          if (value) {
            const updatedUrl = `${url}${url.includes('?') ? '&' : '?'}access_token=${value}`;
            return orgOpen(updatedUrl, target, features);
          } else {
            return orgOpen(url, target, features);
          }
        })
        .catch((reason) => console.log(reason));
    } else {
      return orgOpen(url, target, features);
    }
    return null;
  };
  return (): Promise<boolean> => {
    return keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        enableLogging: !environment.production,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    });
  };
}
