import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserAccount } from '../models/account/user-account.model';
import { Constants } from '../models/enum/constants';
import { User } from '../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class PageManager {
  public currentUserAccount$: BehaviorSubject<UserAccount>;
  public user: User;

  private _storeName = '_session-store';

  private store: any;

  private _subs: Subscription[] = [];

  constructor(public router: Router) {
    this.currentUserAccount$ = new BehaviorSubject<UserAccount>(
      this.getCurrentUserAccountFromStorage()
    );
  }

  getCurrentUserAccountFromStorage(): any {
    return this.getData('USER_ACCOUNT', 'currentAccount', Constants.Storage.LOCAL);
  }

  getNodeStatusFromStorage(): any {
    return this.getData('NODE', 'status', Constants.Storage.LOCAL);
  }

  getCoordinateFromStorage(): any {
    return this.getData('DEVICE', 'gps', Constants.Storage.LOCAL);
  }
  private getDeviceConnectedFromStorage(): any {
    return this.getData('DEVICE', 'device', Constants.Storage.LOCAL);
  }

  public setCurrentUserAccount(userAccount: UserAccount): void {
    if (!userAccount) {
      this.currentUserAccount$.next(this.getCurrentUserAccountFromStorage());
    } else {
      this.currentUserAccount$.next(userAccount);
      this.storeData('USER_ACCOUNT', 'currentAccount', userAccount, Constants.Storage.LOCAL);
    }
  }
  getStore(storageType: Constants.Storage): any {
    const sessionStore = this.isLocal(storageType)
      ? localStorage.getItem(this._storeName)
      : sessionStorage.getItem(this._storeName);
    return sessionStore ? JSON.parse(sessionStore) : {};
  }

  public storeData(
    scope: string,
    key: string,
    data: any,
    storageType: Constants.Storage = Constants.Storage.LOCAL
  ): boolean {
    this.store = this.getStore(storageType);
    if (!this.store[scope]) {
      this.store[scope] = {};
    }
    this.store[scope][key] = JSON.stringify(data);

    this.persist(storageType);

    return this.store[scope];
  }

  public getData(
    scope: string,
    key: string,
    storageType: Constants.Storage = Constants.Storage.LOCAL
  ): any | boolean {
    this.store = this.getStore(storageType);
    if (!this.store[scope] || !this.store[scope][key]) {
      return false;
    }
    return JSON.parse(this.store[scope][key]);
  }

  public removeData(
    scope: string,
    key: string,
    storageType: Constants.Storage = Constants.Storage.LOCAL
  ): boolean {
    this.store = this.getStore(storageType);

    if (!this.store[scope] || !this.store[scope][key]) {
      return false;
    }
    delete this.store[scope][key];

    this.persist(storageType);

    return true;
  }

  public clearAllData(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  isLocal(type: Constants.Storage): boolean {
    return type === Constants.Storage.LOCAL;
  }

  private persist(storageType: Constants.Storage) {
    if (this.isLocal(storageType)) {
      localStorage.setItem(this._storeName, JSON.stringify(this.store));
    } else {
      sessionStorage.setItem(this._storeName, JSON.stringify(this.store));
    }
  }
}
