/**
 * Util API
 * The Util RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MeterPojo { 
    name?: string;
    meterNumber?: string;
    address?: string;
    meterType?: MeterPojo.MeterTypeEnum;
    provider?: MeterPojo.ProviderEnum;
    phoneNumber?: string;
}
export namespace MeterPojo {
    export type MeterTypeEnum = 'PREPAID' | 'POSTPAID';
    export const MeterTypeEnum = {
        PREPAID: 'PREPAID' as MeterTypeEnum,
        POSTPAID: 'POSTPAID' as MeterTypeEnum
    };
    export type ProviderEnum = 'IKEDC' | 'EKEDC' | 'KEDCO' | 'PHED' | 'JED' | 'IBEDC' | 'KAEDCO' | 'AEDC' | 'EEDC' | 'BEDC';
    export const ProviderEnum = {
        IKEDC: 'IKEDC' as ProviderEnum,
        EKEDC: 'EKEDC' as ProviderEnum,
        KEDCO: 'KEDCO' as ProviderEnum,
        PHED: 'PHED' as ProviderEnum,
        JED: 'JED' as ProviderEnum,
        IBEDC: 'IBEDC' as ProviderEnum,
        KAEDCO: 'KAEDCO' as ProviderEnum,
        AEDC: 'AEDC' as ProviderEnum,
        EEDC: 'EEDC' as ProviderEnum,
        BEDC: 'BEDC' as ProviderEnum
    };
}


