/**
 * Util API
 * The Util RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Country } from './country';
import { PortalUser } from './portal-user';


export interface State { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: State.StatusEnum;
    deactivatedBy?: PortalUser;
    name?: string;
    code?: string;
    disco?: State.DiscoEnum;
    country?: Country;
}
export namespace State {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type DiscoEnum = 'IKEDC' | 'EKEDC' | 'KEDCO' | 'PHED' | 'JED' | 'IBEDC' | 'KAEDCO' | 'AEDC' | 'EEDC' | 'BEDC';
    export const DiscoEnum = {
        IKEDC: 'IKEDC' as DiscoEnum,
        EKEDC: 'EKEDC' as DiscoEnum,
        KEDCO: 'KEDCO' as DiscoEnum,
        PHED: 'PHED' as DiscoEnum,
        JED: 'JED' as DiscoEnum,
        IBEDC: 'IBEDC' as DiscoEnum,
        KAEDCO: 'KAEDCO' as DiscoEnum,
        AEDC: 'AEDC' as DiscoEnum,
        EEDC: 'EEDC' as DiscoEnum,
        BEDC: 'BEDC' as DiscoEnum
    };
}


