import { Component, OnInit } from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {DarkModeService} from "../../../services/dark-mode.service";



@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  imports: [
    IonicModule
  ],
  standalone: true
})
export class SideNavComponent implements OnInit {

  darkModeEnabled: boolean;

  constructor(private darkModeService: DarkModeService) {}

  ngOnInit(): void {
    this.darkModeEnabled = this.darkModeService.isDarkModeEnabled();
  }

  toggleDarkMode(): void {
    this.darkModeEnabled = !this.darkModeEnabled;
    this.darkModeService.toggleDarkMode(this.darkModeEnabled);
  }

}
