<!-- App Capsule -->
<div id="appCapsule">

  <!-- Wallet Card -->
  <div class="section wallet-card-section pt-1">
    <div class="wallet-card">
      <!-- Balance -->
      <div class="balance">
        <div class="left">
          <span class="title">Total Balance</span>
          <h1 class="total">$ 2,562.50</h1>
        </div>
        <div class="right">
          <a href="#" class="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
            <ion-icon name="add-outline"></ion-icon>
          </a>
        </div>
      </div>
      <!-- * Balance -->
      <!-- Wallet Footer -->
      <div class="wallet-footer">
        <div class="item">
          <a href="#" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
            <div class="icon-wrapper bg-danger">
              <ion-icon name="arrow-down-outline"></ion-icon>
            </div>
            <strong>Withdraw</strong>
          </a>
        </div>
        <div class="item">
          <a href="#" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
            <div class="icon-wrapper">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <strong>Send</strong>
          </a>
        </div>
        <div class="item">
          <a href="app-cards.html">
            <div class="icon-wrapper bg-success">
              <ion-icon name="card-outline"></ion-icon>
            </div>
            <strong>Cards</strong>
          </a>
        </div>
        <div class="item">
          <a href="#" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
            <div class="icon-wrapper bg-warning">
              <ion-icon name="swap-vertical"></ion-icon>
            </div>
            <strong>Exchange</strong>
          </a>
        </div>

      </div>
      <!-- * Wallet Footer -->
    </div>
  </div>
  <!-- Wallet Card -->


  <!-- Services -->
  <div class="section mt-4">
    <div class="section-heading">
      <h2 class="title">Services</h2>
      <a href="app-transactions.html" class="link">View All</a>
    </div>
    <div class="row">
      <div class="col-3">
        <a [routerLink]="'purchase-electricity'">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-activity service-icon">
              <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Electricity</h5>
        </a>
      </div>

      <div class="col-3">
        <a [routerLink]="'signup'">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-droplet service-icon">
              <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"></path>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Water</h5>
        </a>
      </div>
      <div class="col-3">
        <a href="service.html">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-wifi service-icon">
              <path d="M5 12.55a11 11 0 0 1 14.08 0"></path>
              <path d="M1.42 9a16 16 0 0 1 21.16 0"></path>
              <path d="M8.53 16.11a6 6 0 0 1 6.95 0"></path>
              <line x1="12" y1="20" x2="12.01" y2="20"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Internet</h5>
        </a>
      </div>

      <div class="col-3">
        <a href="service.html">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-monitor service-icon">
              <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
              <line x1="8" y1="21" x2="16" y2="21"></line>
              <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Television</h5>
        </a>
      </div>
      <div class="col-3">
        <a href="service.html">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-bar-chart-2 service-icon">
              <line x1="18" y1="20" x2="18" y2="10"></line>
              <line x1="12" y1="20" x2="12" y2="4"></line>
              <line x1="6" y1="20" x2="6" y2="14"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Investment</h5>
        </a>
      </div>

      <div class="col-3">
        <a href="service.html">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-tablet service-icon">
              <rect x="4" y="2" width="16" height="20" rx="2" ry="2"></rect>
              <line x1="12" y1="18" x2="12.01" y2="18"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Mobile</h5>
        </a>
      </div>
      <div class="col-3">
        <a href="service.html">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-plus-square service-icon">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Medical</h5>
        </a>
      </div>
      <div class="col-3">
        <a href="service.html">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-more-horizontal service-icon">
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Other</h5>
        </a>
      </div>
    </div>
  </div>
  <!-- * Services -->

  <!-- Transactions -->
  <div class="section mt-4">
    <div class="section-heading">
      <h2 class="title">Transactions</h2>
      <a href="app-transactions.html" class="link">View All</a>
    </div>
    <div class="transactions">
      <!-- item -->
      <a href="app-transaction-detail.html" class="item">
        <div class="detail">
          <img src="assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48">
          <div>
            <strong>Amazon</strong>
            <p>Shopping</p>
          </div>
        </div>
        <div class="right">
          <div class="price text-danger"> - $ 150</div>
        </div>
      </a>
      <!-- * item -->
      <!-- item -->
      <a href="app-transaction-detail.html" class="item">
        <div class="detail">
          <img src="assets/img/sample/brand/2.jpg" alt="img" class="image-block imaged w48">
          <div>
            <strong>Apple</strong>
            <p>Appstore Purchase</p>
          </div>
        </div>
        <div class="right">
          <div class="price text-danger">- $ 29</div>
        </div>
      </a>
      <!-- * item -->
      <!-- item -->
      <a href="app-transaction-detail.html" class="item">
        <div class="detail">
          <img src="assets/img/sample/avatar/avatar3.jpg" alt="img" class="image-block imaged w48">
          <div>
            <strong>Alex Ljung</strong>
            <p>Transfer</p>
          </div>
        </div>
        <div class="right">
          <div class="price">+ $ 1,000</div>
        </div>
      </a>
      <!-- * item -->
      <!-- item -->
      <a href="app-transaction-detail.html" class="item">
        <div class="detail">
          <img src="assets/img/sample/avatar/avatar4.jpg" alt="img" class="image-block imaged w48">
          <div>
            <strong>Beatriz Brito</strong>
            <p>Transfer</p>
          </div>
        </div>
        <div class="right">
          <div class="price text-danger">- $ 186</div>
        </div>
      </a>
      <!-- * item -->
    </div>
  </div>
  <!-- * Transactions -->
</div>
<!-- * App Capsule -->
